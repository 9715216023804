import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import LevelDialog from './Dialog/LevelDialog'
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import DomainDropDown from './../components/DomainDropDown';
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from './../constants/common';
import { Heart } from 'react-spinners-css';
import { getBootstrapWidthXs } from '../utils';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

class LevelPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { levels: [], dialogOpen: false, currentLevel: null, currentDomainGuid: '' , width: window.innerWidth };
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    componentDidMount() {
    }

    updateLevelsList = (domainGuid) => {
        this.setState({ loading: true })
        Api.getLevelsByDomain(domainGuid)
            .then(res => { 
                this.setState({ levels: res.data, currentDomainGuid: domainGuid, loading: false }); 
            })
            .catch(err => {
                this.setState({ loading: false })
                Api.logger.errorWithSnackbar(`Fout bij bijwerken van lijst met niveaus`, this, err)
            });
    }

    deleteLevel = (id) => Api.deleteLevel(id)
    .then(() => {this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS); this.updateLevelsList(this.state.currentDomainGuid)})
    .catch(err =>Api.logger.errorWithSnackbar(`Error deleting level=${id}`, this, err));

    adjust = (color, amount) => {
        
        return color ? '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)) :
        '#CCC'
    }

    render() {
        
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}  pageTitle="Manage levels" onAddFabClick={() => this.showLevelDialog()} />
                <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                    <Toolbar style={{ background: 'white' }}>
                        <DomainDropDown onDomainSelected={(domainGuid) => this.updateLevelsList(domainGuid)} />
                    </Toolbar>
                </AppBar>

                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                    <Container maxWidth="xl" style={{marginTop: '130px'}}>
                        <Grid container spacing={3} >
                            {                                
                                this.state.levels.map((level) => {
                                    var width = getBootstrapWidthXs(this.state.width)

                                    return (
                                        <Grid item xs={width} key={level.Id} cols={level.cols || 1}>
                                            <Card elevation={3}>
                                                <CardContent>
                                                    <Grid container spacing={3} alignItems="center" justify="center">
                                                        <Grid item xs={2}>
                                                            <div style={{ backgroundColor: this.adjust(level.Color, -50), borderRadius: 3, width: 42, height: 42, alignContent: 'center' }}>
                                                                <FavoriteIcon fontSize='large' style={{ fill: this.adjust(level.Color, 20), marginTop: 3, marginLeft: 3 }} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={10}><Typography variant="h6">{level.Name}</Typography></Grid>
                                                    </Grid>
                                                </CardContent>
                                                <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end' }}>
                                                    <IconButton aria-label="Bewerk" onClick={() => this.showLevelDialog(level)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="Delete" onClick={() => this.deleteLevel(level.Id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <LevelDialog
                            open={this.state.dialogOpen}
                            onDialogClosed={this.onDialogClosed}
                            currentItem={this.state.currentLevel}
                        />
                    </Container>
                }

            </div>);
    }

    showLevelDialog = async (level) => await this.setState({ currentLevel: level, dialogOpen: true });

    onDialogClosed = (dialogResult) => {

        this.setState({ dialogOpen: false });
        
        if (dialogResult.isCanceled){
            return;
        }        

        let param = {
            name: dialogResult.name,
            Color: dialogResult.colorHex,
            Domain : {Id: this.state.currentDomainGuid},
            UsedForAvailableTime: dialogResult.usedForAvailableTime
        }
        
        let promise; 

        if (!this.state.currentLevel) { // dialog for new subscription            
            promise = Api.createLevel(param);
        }
        else {
            param.id = this.state.currentLevel.Id;
            promise = Api.updateLevel(param);
        }

        promise
        .then(() => {
            this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
               
        this.updateLevelsList(this.state.currentDomainGuid)})
        .catch(err =>Api.logger.errorWithSnackbar(`Fout tijdens het bewerken van niveau`, this, err));   
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };
}


export default withSnackbar(LevelPage);