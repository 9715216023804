import { Card, CardContent, Select, MenuItem } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import UnitFormDialog from './Dialog/UnitFormDialog'
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { connect } from "react-redux";
import actions from "../actions.js";
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from '../constants/common';
import { Heart } from 'react-spinners-css';
import { BasePage } from './BasePage';
import { getBootstrapWidthXs } from '../utils';
import Moment from 'react-moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import 'moment/locale/nl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UnitFormAddDialog from './Dialog/UnitFormAddDialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemText from '@mui/material/ListItemText';
import { EMPTY_GUID } from './../constants/common';
import Pagination from '@mui/material/Pagination';

const pageSize = 3;

class UnitFormPage extends BasePage {
    
    constructor(props) {        
        super(props, {dialogAddOpen: false,
            units: [],
            selectedUnitId: 'all-units-id',
            statuses: [],
            selectedStatus: 'Alle',
            currentPage: 1,
            totalCount: 1
        });
    }

    componentDidMount() {
        this.updateUnitFormsList();      
    }

    componentDidUpdate(prevProps) {
        if(prevProps.currentTeam !== this.props.currentTeam){
            this.updateUnitFormsList()
        }
    }


    updateUnitFormsList = () => this.getItems(Api.getUnitForms,
         { 
             teamId: this.props.currentTeam,
             unitId: this.state.selectedUnitId === 'all-units-id' ? null : this.state.selectedUnitId,
             isGroupForms: this.state.selectedUnitId === EMPTY_GUID ? true:false,
             status: this.state.selectedStatus === 'Alle' ? null : this.state.selectedStatus,
             pageNumber: this.state.currentPage,
             pageSize: pageSize
         }, 
         
         res => {
        res.data.Units.splice(0, 0, {Id:'all-units-id', Name:'Alle'})
        res.data.Statuses.splice(0,0,'Alle')
        this.setState({ items: res.data.PageData, units: res.data.Units, statuses: res.data.Statuses, totalCount: res.data.TotalCount })
    },
        `Error get unit forms`);

    deleteUnitForm = (id) => this.deleteItem(id, Api.deleteUnitForm, () => {this.updateUnitFormsList()}, "Succesvol verwijderd", "Error on delete theme")

    onFilterUnit = async (event) => {
        await this.setState({selectedUnitId: event.target.value})
        this.updateUnitFormsList()
    }

    onFilterStatus = async (event) => {
        
        await this.setState({selectedStatus: event.target.value, selectedUnitId: event.target.value === 'Ingepland' ? this.state.selectedUnitId : 'all-units-id'})
        console.log(this.state.selectedStatus)
        this.updateUnitFormsList()
    }

    onPageChange = async (event, pageNumber) => {
        await this.setState({currentPage: pageNumber}) 
        this.updateUnitFormsList()
        console.log(pageNumber)
    }

    onStartOcrResults = () => {
        this.setState({loading: true})
        Api.getOcrResults(false).then((res)=>{
            this.updateUnitFormsList()
            this.setState({loading: false})
        })
        .catch(()=>{
            this.setState({loading: false})})
    }

    renderItem(unitForm) {
        let width = getBootstrapWidthXs(this.state.width)
        var statusView = {
            color: '#733',
            text: unitForm.Status,
            icon: <HelpOutlineIcon fontSize='small'/>
        };

        if (unitForm.Status === "Ingepland") {
            statusView.color = '#4AB897'
            statusView.icon = <CheckIcon/>
        }
        else if (unitForm.Status === "Verwerken")
        {
            statusView.color = '#777'
            statusView.icon = <AccessTimeIcon fontSize='small'/>
        }
        return (
            <Grid item xs={width} key={unitForm.Id}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={12}><Typography variant="subtitle1" >{unitForm.FileName}</Typography></Grid>
                            <Grid item xs={12}><Typography variant="body2" style={{color:'#555', fontStyle:'italic'}}><Moment format="DD-MM-YYYY HH:mm (dddd)" locale='nl'>{unitForm.CreatedOn}</Moment></Typography></Grid>
                            <Grid item xs={1} style={{marginTop:5, color: statusView.color}}>{statusView.icon}</Grid>
                            <Grid item xs={11}><Typography variant="body2" style={{color: statusView.color}}>{unitForm.Status} {unitForm.Unit !== null ? " for " + unitForm.Unit.Name : ""}</Typography></Grid>
                                                        
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="Bewerk" onClick={() => this.showDialog(unitForm)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label="Delete" onClick={() => this.deleteUnitForm(unitForm.Id)}>
                            <DeleteIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

    render() {
       // console.log(typeof this.state.selectedUnits)
        return (
            <div>
                <DidoDrawer pageTitle="Formulieren" onAddFabClick={() => this.setState({ dialogAddOpen: true })} />
                <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                    <Toolbar style={{ background: 'white' }}>
                        <Grid container style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <Grid item xs={3}>
                                <IconButton
                                    disabled={this.state.loading}
                                    onClick={this.onStartOcrResults}
                                >
                                    <DownloadIcon /><Typography variant='subtitle2'>OCR-resultaten</Typography>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={7}>
                            <div style={{ marginLeft: 60, width: 120,display: 'inline' }}>
                                Staat&nbsp;
                                <Select
                                    value={this.state.selectedStatus}
                                    onChange={this.onFilterStatus}
                                    style={{ height: 50 }}
                                >
                                    {
                                        this.state.statuses?.map((status) => (
                                            <MenuItem key={status} value={status}>
                                                <ListItemText primary={status} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                </div>


                                <div style={{ marginLeft: 60, width: 120,display: 'inline' }}>
                                Kamer&nbsp;
                                <Select
                                    disabled = {this.state.selectedStatus !== 'Ingepland'}
                                    value={this.state.selectedUnitId}
                                    onChange={this.onFilterUnit}
                                    style={{height: 50}}
                                >
                                    {
                                        this.state.units?.map((unit) => (
                                            <MenuItem key={unit.Id} value={unit.Id}>
                                                <ListItemText primary={unit.Name} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                </div>
                                
                            </Grid>

                        </Grid>

                        
                    </Toolbar>
                </AppBar>
                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                    <Container maxWidth="xl" style={{ marginTop: '140px' }}>
                        <Grid spacing={3} container>
                            {
                                this.state.items.map(unitForm => this.renderItem(unitForm))
                            }
                        </Grid>
                        <div style={{justifyContent:'center', marginTop: 20}}>
                            <Pagination count={Math.ceil(this.state.totalCount / pageSize)} page={this.state.currentPage} onChange={this.onPageChange} />
                            </div>
                        <UnitFormDialog
                            maxWidth='lg'
                            open={this.state.dialogOpen}
                            onDialogClosed={this.onDialogClosed}
                            currentItem={this.state.currentItem}
                        />
                        <UnitFormAddDialog
                        style={{width: '50vw'}}
                        open={this.state.dialogAddOpen}
                        onDialogClosed={this.onDialogAddClosed}
                        />
                    </Container>
                }

            </div>);
    }

    onDialogAddClosed = (dialogResult) => {
        this.setState({ dialogAddOpen: false });

        if (dialogResult.isCanceled){
            return;
        }      

        const formData = new FormData();
        console.log(dialogResult)

        dialogResult.files.forEach(file => {
            formData.append("FormFiles", file)
        })
        
        formData.append("TeamId", this.props.currentTeam)

        Api.createUnitForm(formData)
        .then((newForm) => {
            this.props.enqueueSnackbar("Het formulier is toegevoegd aan verwerking", SNACK_SUCCESS)
            this.updateUnitFormsList(); //this.item.push(newForm)
            //
        })
        .catch(err =>Api.logger.errorWithSnackbar(`Fout bij toevoegen van formulier`,this, err))
        

    }

    onDialogClosed = (dialogResult) => {

        this.setState({ dialogOpen: false });
        
        //console.log(dialogResult.isCanceled)
        if (dialogResult.isCanceled){
            return;
        }      
    }
}


export default connect((state)=>{    
    return {        
        currentOrg: state.didoReducer.currentOrg,
        currentTeam: state.didoReducer.currentTeam
    }}, actions)(withSnackbar(UnitFormPage));