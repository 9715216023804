import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Avatar, Grid, Typography } from '@mui/material';
import * as Api from '../../services/api/common-api'
import  ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import {withSnackbar} from 'notistack';
import { styled } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';
const FormRow = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 500
  }));

const Key = styled('span')(({ theme }) => ({
    backgroundColor: '#f7fafc',
    border: '1px solid #cbd5e0',
    borderRadius: '0.25rem',
    padding: '0.25rem'
  }));

const PreviewImage = styled('img')(({ theme }) => ({
    alignItems: 'center',
    border: '1px solid #cbd5e0',
    display: 'flex',
    justifyContent: 'center',

    marginTop: '1rem',
    maxHeight: '16rem',
    maxWidth: '42rem'
}));

let fileOutsideReact = null;

class KanbanTaskDialog extends React.Component {
    constructor(props) {
        super(props);

        this.flushState()


        document.addEventListener('paste', function (evt) {            

            const clipboardItems = evt.clipboardData.items;
            const items = [].slice.call(clipboardItems).filter(function (item) {
                // Filter the image items only
                return /^image\//.test(item.type);
            });

            if (items.length === 0) {
                return;
            }

            const item = items[0];
            const blob = item.getAsFile();

            const imageEle = document.getElementById('preview');
            imageEle.src = URL.createObjectURL(blob);
            let file = new File([blob], "screenshot.png", { type: "image/png", lastModified: new Date().getTime() }, 'utf-8');
            
            fileOutsideReact = file;            

            let container = new DataTransfer();
            container.items.add(file);
            document.querySelector('#file_input').files = container.files;
        });

    }

    flushState = async () => {        
        const initialState = {
            objective: null,
            selectedTeamGuid: null,
            selectedBoardGuid: null,
            selectedThemeGuid: null,
            boards: [],
            previewFile: null,
            previousSelectedTeamGuid: null
        };
        if (this.state) await this.setState(initialState)
        else this.state = initialState;
    }

    // Objective
    onObjectiveChange = (evt) => this.setState({ objective: evt.currentTarget.value });
    currentObjective = () => this.state.objective ?
        this.state.objective :
        this.props.currentItem ?
            this.props.currentItem.Objective :
            ''

    // Theme
    onChangeTheme  = async (event) => {        
        await this.setState({selectedThemeGuid : event.target.value});
    }    
    currentThemeGuid = () => {
        return (this.state.selectedThemeGuid) ?
            this.state.selectedThemeGuid :

            (this.props.currentItem) ?
                this.props.currentItem.Theme.Id :
                this.props.themes.length > 0 ?
                    this.props.themes[0].Id :
                    ""
    }

    // Team
    onChangeTeam = async (event) => {
        await this.setState({ selectedTeamGuid: event.target.value });
        this.loadBoards(event.target.value)
    }

    currentTeamGuid = () => {
        if (this.state.selectedTeamGuid)
            return this.state.selectedTeamGuid;

        if (this.props.teams.length > 0) {
            if (this.props.teams[0].Id !== this.state.previousSelectedTeamGuid && this.props.open) {
                this.loadBoards(this.props.teams[0].Id)
                this.setState({ previousSelectedTeamGuid: this.props.teams[0].Id})
            }            
            
            return this.props.teams[0].Id;
        }

        return "";
    }

    // Board
    loadBoards = (guid) => Api.getBoardsForTeam(guid)
        .then(async (res) => { await this.setState({ boards: res.data, selectedBoardGuid: null }) })
        .catch(err => Api.logger.errorWithSnackbar(`Error while loading boards for team ${guid}`, this, err));

    onChangeBoard = async (event) => {await this.setState({ selectedBoardGuid: event.target.value });}

    currentBoardGuid = () => {        
        if (this.state.selectedBoardGuid) return this.state.selectedBoardGuid;

        
        if (this.state.boards.length > 0) {            
            return this.state.boards[0].Id;
        }

        const currentTeamGuid = this.currentTeamGuid();        
        if (this.state.boards.length === 0 && currentTeamGuid)
            this.loadBoards(currentTeamGuid)

        return ""
    }

    handleUploadClick = (event) => {
        if (event.target.files.length === 0) return;
        var file = event.target.files[0];
        fileOutsideReact = file;
        
        const reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onloadend = function () {
            this.setState({previewFile: [reader.result]})    
        }.bind(this);    
      };

    onOkClick = async () => {
        if (!this.state.objective) {
            await this.setState({ objective: this.currentObjective() })
        }

        if (!this.state.selectedThemeGuid) {
            await this.setState({ selectedThemeGuid: this.currentThemeGuid() })
        }

        if (!this.state.selectedBoardGuid) {
            await this.setState({selectedBoardGuid: this.currentBoardGuid()})
        }
        this.setState({file: fileOutsideReact});
        
        this.props.dialogAction(false, this.state);
        this.flushState()
    }

    onCancelClick = () => {
        this.props.dialogAction(true);
        this.flushState()
    }

    render = () => {
        let currentThemeGuid = this.currentThemeGuid()
        let currentTeamGuid = this.currentTeamGuid();

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11} style={{ alignSelf: 'center' }}>{this.props.currentItem ? "Actie bewerken" : "Nieuwe actie"}</Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField 
                            fullWidth
                                name="Objective"
                                multiline
                                required
                                label="Doelstelling"
                                defaultValue={this.currentObjective()}
                                onChange={this.onObjectiveChange} 
                                minRows={3}
                                />
                                
                        </FormRow>
                        <FormRow>
                            <TextField
                                select
                                fullWidth
                                value={currentThemeGuid}
                                onChange={(e) => this.onChangeTheme(e)}
                                label="Thema"
                            >
                                {this.props.themes.map((theme) => {
                                    return (

                                        <MenuItem key={theme.Id} value={theme.Id}>
                                            <div style={{ display: 'flex' }}>
                                                <ListItemIcon>
                                                    <Avatar src={theme.Avatar} style={{ width: 24, height: 24 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={theme.Name} />
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormRow>
                        <FormRow style={{ display: this.props.currentItem != null || this.props.currentUser.BelongsToTeam != null || this.props.isTeamFixed ? 'none' : 'inline-flex', minWidth: 500 }}>
                            <TextField
                                select
                                fullWidth
                                label="Team"
                                value={currentTeamGuid}
                                onChange={(e) => this.onChangeTeam(e)}
                            >
                                {this.props.teams.map((team) => {
                                    return (
                                        <MenuItem key={team.Id} value={team.Id}>
                                            <div style={{ display: 'flex' }}>
                                                <ListItemIcon>
                                                    <Avatar src={team.Avatar} style={{ width: 24, height: 24 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={team.Name} />
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormRow>
                        <FormRow style={{ display: this.props.currentItem != null || this.props.isBoardFixed ? 'none' : 'inline-flex' }}>
                            <TextField
                                select
                                label="Staat"
                                fullWidth
                                value={this.currentBoardGuid()}
                                onChange={(e) => this.onChangeBoard(e)}                                
                            >
                                {this.state.boards.map((board) => {
                                    return (
                                        <MenuItem key={board.Id} value={board.Id}>{board.Name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormRow>                                          
                        {
                            this.props.currentItem && this.props.isBoardFixed ?
                                ""  :
                                <FormRow style={{ border: '1px solid #ccc', borderRadius: '0.3rem' }}>
                                    <Typography variant='body2' style={{ marginLeft: 10 }}>Attachment</Typography>
                                    <div style={{ margin: 20 }}>
                                        <Typography variant='body2'>Gebruik <Key>Shift</Key> + <Key ><WindowIcon style={{ verticalAlign: 'middle' }} fontSize='small' /></Key> + <Key>S</Key> en <Key>Ctrl</Key> + <Key>V</Key> om plaatjes toe te voegen.</Typography>
                                        <Typography variant='body2' style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>--- Of ---</Typography>
                                        <div>
                                            <label htmlFor="file_input" style={{ fontSize: '0.875rem', marginRight: 5 }}>Upload een bestand als bijlage</label>
                                            <input id="file_input" type="file" onChange={this.handleUploadClick} />
                                        </div>

                                        <PreviewImage id="preview"
                                            src={
                                                this.state.previewFile ? this.state.previewFile :
                                                    this.props.currentItem && this.props.currentItem.Attachment ?
                                                        this.props.currentItem.Attachment :
                                                        undefined
                                            } />

                                    </div>
                                </FormRow>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) =>  ({ currentUser: state.didoReducer.currentUser })

export default connect(mapStateToProps)(withSnackbar(KanbanTaskDialog));