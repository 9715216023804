import { Card, CardContent, Grid, Select, MenuItem, Checkbox, Menu, Button } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import UserDialog from './Dialog/UserDialog'
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { EMPTY_GUID, SNACK_ERROR, SNACK_SUCCESS, TOKEN_KEY, USER_KEY } from '../constants/common';
import { IMPERSONALIZATION } from '../constants/roles';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import OrgDropDown from './../components/OrgDropDown';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { getBootstrapWidthXs } from '../utils';
import ListItemText from '@mui/material/ListItemText';
import { faSortAlphaDown, faSortAlphaDownAlt, faUsers, faTheaterMasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/material/styles';
import DidoHeart from './../components/DidoHeart';

const ButtonFilterDesktop = styled(Button)(({ theme }) => ({
    border: "1px solid #CCC",
    color: "#666",
    marginRight: '1vw',
    '&:hover':{
        border: "1px solid #CCC",
    }
  }));

  const ButtonFilterMobile = styled(Button)(({ theme }) => ({

    color: "#666",
    marginRight: '0',
    padding: '0'
  }));

class UsersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { users: [], dialogOpen: false, profiles: [], 
            editableItem: null, organizations: [], teamsForOrg: [], selectedOrgGuid: null, 
            width: window.innerWidth, 
            sortingAsc: true, selectAllTeams:true, allTeamsByOrg: [], filtredTeamsIds:["selectAllTeams"], filtredProfileIds:[],
            isTeamFilterMenuOpened: false, anchorElForTeams: null,
            isProfileFilterMenuOpened: false, anchorElForProfiles: null,
        };
    }

    render() {        
        // filter by teams
        var filtredUsers = this.state.users.filter(u => u.BelongsToTeam == null || this.state.filtredTeamsIds.includes(u.BelongsToTeam.Id));
        
        // filter by profiles
        filtredUsers = filtredUsers.filter(u => this.state.filtredProfileIds.some(f => u.Profiles.some( p=> p.Id === f)))

        // sorting
        filtredUsers.sort((a, b) => this.state.sortingAsc ? a.Name.localeCompare(b.Name) : b.Name.localeCompare(a.Name))

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} noFabClick pageTitle="Gebruikers beheren" />
                <Fab style={{ backgroundColor: '#4AB897', border: '2px solid', borderColor:'#0A8857',  zIndex: 1200, color: 'white', right: '8vw', top: this.state.width < 900 ? '85vh' : '3vh', position: 'fixed' }} aria-label="add">
                    <AddIcon onClick={() => this.showUserDialog()} />
                </Fab>

                <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                    <Toolbar style={{ background: 'white' }}>
                        <div style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                {
                                    this.props.currentUser?.IsSystemAdmin &&
                                        <OrgDropDown onOrgSelected={(orgGuid) => this.setState({ loading: true, selectedOrgGuid: orgGuid }, () => this.updateUsersList())} />
                                }
                            </div>
                            {
                                this.state.width > 900 ?
                                    <div style={{ marginRight: '15vw' }}>
                                        <ButtonFilterDesktop
                                            onClick={e => this.onClickSortButton(e)}
                                            variant='outlined'
                                            startIcon={<FontAwesomeIcon icon={this.state.sortingAsc ? faSortAlphaDown : faSortAlphaDownAlt} />}>
                                            Soort
                                        </ButtonFilterDesktop>

                                        <ButtonFilterDesktop
                                            onClick={e => this.onClickTeamFilterButton(e)}
                                            variant='outlined'
                                            startIcon={<FontAwesomeIcon icon={faUsers}/>}>
                                            Team
                                        </ButtonFilterDesktop>

                                        <ButtonFilterDesktop
                                            onClick={e => this.onClickProfileFilterButton(e)}
                                            variant='outlined'
                                            startIcon={<FontAwesomeIcon icon={faTheaterMasks}/>}>
                                            Rol
                                        </ButtonFilterDesktop>
                                    </div>
                                    :
                                    <div>
                                        <ButtonFilterMobile
                                        onClick={e => this.onClickSortButton(e)}
                                        variant='text'
                                        startIcon={<FontAwesomeIcon icon={this.state.sortingAsc ? faSortAlphaDown : faSortAlphaDownAlt} />} />  

                                        <ButtonFilterMobile
                                        onClick={e => this.onClickTeamFilterButton(e)}
                                        variant='text'
                                        startIcon={<FontAwesomeIcon icon={faUsers}/>} />

                                        <ButtonFilterMobile
                                        onClick={e => this.onClickProfileFilterButton(e)}
                                        variant='text'
                                        startIcon={<FontAwesomeIcon icon={faTheaterMasks}/>} />      
                                    </div>
                            }
                            
                        </div>
                       
                        <Menu
                            anchorEl={this.state.anchorElForTeams}
                            open={this.state.isTeamFilterMenuOpened}
                            onClose={e => this.onCloseTeamFilterMenu(e)}
                        >
                            <MenuItem key="selectAllTeams" value="selectAllTeams" onClick={e => this.onCloseTeamFilterMenu(e, "selectAllTeams")} divider>
                                <Checkbox checked={this.state.filtredTeamsIds.includes("selectAllTeams")} />
                                <ListItemText primary="Selecteer alles" />
                            </MenuItem> 
                            
                            {
                                this.state.allTeamsByOrg.map(team => (
                                    <MenuItem key={team.Id} value={team.Id} onClick={e => this.onCloseTeamFilterMenu(e, team.Id)}>
                                        <Checkbox checked={this.state.filtredTeamsIds.some(id => id === team.Id)} />
                                        <ListItemText primary={team.Name} />
                                    </MenuItem>
                                ))
                            }
                        </Menu>

                        <Menu
                            anchorEl={this.state.anchorElForProfiles}
                            open={this.state.isProfileFilterMenuOpened}
                            onClose={this.onCloseProfileFilterMenu}
                        >
                            {
                                this.state.profiles.map(profile => (
                                    <MenuItem key={profile.Id} value={profile.Id} onClick={e => this.onCloseProfileFilterMenu(e, profile.Id)}>
                                        <Checkbox checked={this.state.filtredProfileIds.some(id => id === profile.Id)} />
                                        <ListItemText primary={profile.Name} />
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                       
                    </Toolbar>
                </AppBar>
                <DidoHeart loading={this.state.loading} />
                <Container maxWidth="xl" style={{ marginTop: '140px' }}>
                    <Grid container spacing={2}>
                        {
                            filtredUsers.map((user) => {
                                var w = getBootstrapWidthXs(this.state.width)
                                return (
                                    <Grid item xs={w} key={user.Id}>
                                        <Card >
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe" src={user.Avatar}></Avatar>
                                                }
                                                title={
                                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{user.Name}</Typography>
                                                }
                                                subheader={
                                                    <a href={"mailto:" + user.Email} style={{ color: '#333' }}>{user.Email}</a>
                                                } />
                                            <CardContent>
                                                <Typography variant="body2" component="span">{user.Profiles.map((profile) => profile.Name).join(', ')} at <span style={{ fontWeight: 'bold' }}>{user.Organization != null ? user.Organization.Name : 'System'}</span></Typography>
                                            </CardContent>
                                            <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end', }}>
                                                <IconButton aria-label="Impersonalization" onClick={() => this.Impersonalization(user.Id)} style={this.canImpersonalization()}>
                                                    <SupervisorAccountIcon />
                                                </IconButton>
                                                <IconButton aria-label="Bewerk" onClick={() => this.showUserDialog(user)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="Delete" onClick={() => this.deleteUser(user.Id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                );
                            })
                        }
                    </Grid>
                    <UserDialog
                        open={this.state.dialogOpen}
                        onUserDialogClose={this.onUserDialogClose}
                        editableItem={this.state.editableItem}
                        profiles={this.state.profiles}
                        organizations={this.state.organizations}
                        teamsForOrg={this.state.teamsForOrg}
                    />
                </Container>
            </div>);
    }
    
    componentWillMount() {
       
        window.addEventListener('resize', this.handleWindowSizeChange);
        
        if (!this.props.currentUser.IsSystemAdmin) {
            this.setState({ loading: true }, () => this.updateUsersList());
        }
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    onClickSortButton = (event) => {
        this.setState({sortingAsc : !this.state.sortingAsc })
    }

    onClickTeamFilterButton = (event) =>
    {
        this.setState({anchorElForTeams: event.currentTarget, isTeamFilterMenuOpened: true})
    }

    onClickProfileFilterButton = (event) =>
    {
        this.setState({anchorElForProfiles: event.currentTarget, isProfileFilterMenuOpened: true})
    }

    onCloseTeamFilterMenu = async (event, itemId) =>
    {
        if (itemId == null) { // click outside - close
            this.setState({anchorElForTeams: null, isTeamFilterMenuOpened: false})
            return;
        }

        if (itemId === "selectAllTeams")
        {
            if (!this.state.selectAllTeams) // select all fired            
                await this.setState({selectAllTeams:true, filtredTeamsIds: ["selectAllTeams"].concat(this.state.allTeamsByOrg.map(t => t.Id))});            
            else // select all unchecked            
                await this.setState({selectAllTeams:false, filtredTeamsIds:[]});    
    
            return
        }       

        if (this.state.filtredTeamsIds.includes(itemId))
            await this.setState({ filtredTeamsIds: this.state.filtredTeamsIds.filter(t => t !== itemId) })
        else
            await this.setState({ filtredTeamsIds: this.state.filtredTeamsIds.concat([itemId])})
    }

    onCloseProfileFilterMenu = async (event, itemId) =>
    {
        console.log(itemId)
        if (itemId == null || itemId==='backdropClick') { // click outside - close
            this.setState({anchorElForProfiles: null, isProfileFilterMenuOpened: false})
            return;
        }

        if (this.state.filtredProfileIds.includes(itemId))
            await this.setState({ filtredProfileIds: this.state.filtredProfileIds.filter(t => t !== itemId) })
        else
            await this.setState({ filtredProfileIds: this.state.filtredProfileIds.concat([itemId])})
    }

    updateUsersList = () => {
        const currentOrg = this.props.currentUser.IsSystemAdmin ? this.state.selectedOrgGuid : this.props.currentUser.CurrentOrgGuid;

        
        Api.getTeamsByOrg(currentOrg).then(async (res) => {
            //console.log(res.data.map(t => t.Id))
            await this.setState({
                allTeamsByOrg: res.data, 
                selectAllTeams: true,
                filtredTeamsIds: ["selectAllTeams"].concat(res.data.map(t => t.Id)) // turn on select all by default
            })
        }) 
        Api.getProfiles().then((res) => this.setState({profiles : res.data, filtredProfileIds: res.data.map(p => p.Id)}))

        //var promise = (this.props.currentUser.IsSystemAdmin) ? Api.getUsersByOrg : Api.getUsers;
        return Api.getUsersByOrg(currentOrg)
        .then(res => this.setState({ loading: false, users: res.data }))
        .catch(err =>this.logWithSnackbar("Error updating users list", this, err));
    }

    onChangeFilterProfiles = (event) =>{
        console.log(event);
        this.setState({filtredProfileIds: event.target.value})
    }

    onSelectAllChange = (e) => {
        console.log(e);
        this.setState({selectAllTeams: e.target.value})
        if (e.target.value === []) {
            this.setState({filtredTeamsIds:[]})
        }
        else {
            this.setState({filtredTeamsIds:this.state.allTeamsByOrg.map(t => t.Id)})
        }
    }

    async showUserDialog(user) {
      
        Promise.all([Api.getOrganizationsWithoutChilds(), Api.getTeamsByLocation(EMPTY_GUID)])
        .then( async (res) =>{
            res.forEach(async (result) => {
                if (result.config.url.toLowerCase().indexOf('organization') !== -1) {
                    await this.setState({organizations: result.data})
                }
                if (result.config.url.toLowerCase().indexOf('teams') !== -1) {
                    await this.setState({teamsForOrg: result.data})
                }
            });
            await this.setState({  dialogOpen: true });   
        })
        .catch(err => this.logWithSnackbar("Error while fetch data for UserDialog", this, err));

        await this.setState({  editableItem: user });   
       
    }

    onUserDialogClose = (dialogResult) => {
        this.setState({ dialogOpen: false });

        if (!dialogResult) {
            return;
        }

        this.setState({loading: true});
               
        const formData = new FormData();    
        formData.append("FormFile", dialogResult.file)        
        formData.append("Name", dialogResult.name)
        formData.append("Email", dialogResult.email)
        for (var i = 0; i < dialogResult.profiles.length; i++)
            formData.append(`Profiles[${i}].Id`, dialogResult.profiles[i])
            
        formData.append("Organization.Id", dialogResult.selectedOrgGuid) // todo take from redux props (when user+org will be connected)
        formData.append("BelongsToTeam.Id", dialogResult.selectedTeamGuid)

        for (i = 0; i < dialogResult.selectedManagedTeamsIds.length; i++)
            formData.append(`ManagedTeams[${i}].Id`, dialogResult.selectedManagedTeamsIds[i])
        
 
        let promise;

        if (!this.state.editableItem) { // dialog for new user            
            promise = Api.createUserWithUpload(formData);
        }
        else {
            formData.append("Id", this.state.editableItem.Id)
            promise = Api.updateUserWithUpload(formData);
        }

        promise.then((res) => {
            if (res.data.ResultCode === 0) {
                this.props.enqueueSnackbar('Gebruiker succesvol toegevoegd', SNACK_SUCCESS)
                this.updateUsersList()
            }
            else {
                this.logWithSnackbar("Error while creating user " + res.data.ErrorMessage, res.data)
            }
        })
            .catch(err => this.logWithSnackbar("Error while creating user", this, err))
            .finally(() => this.setState({ loading: false }))
    }

    deleteUser(id) {
        Api.deleteUser(id).then(
            (r) => {
                if (r.data.ResultCode === 0) {
                    this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
                    this.updateUsersList()
                }
                else {
                    this.logWithSnackbar("Error deleting user " + r.data.ErrorMessage, r.data)
                }
            })
            .catch(err => this.logWithSnackbar("Error deleting user " + id, this, err));
    }

    canImpersonalization = () =>
        (!this.props.currentUser || this.props.currentUser.IsImpersonalization || this.props.currentUser.Roles.indexOf(IMPERSONALIZATION) === -1) ?
            { display: 'none' } :
            { display: 'inline' };


    Impersonalization(id) {
        this.setState({loading: true});
        Api.impersonalization(id)
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem(TOKEN_KEY, response.data);
                    localStorage.removeItem(USER_KEY)
                    window.location = '/';
                }
                else window.location = '/login';
            })
            .catch(err =>this.logWithSnackbar("Fout bij impersonalisatie", this, err))
            .finally(() => this.setState({loading: false}));
    }

    logWithSnackbar(message, error){
        this.props.enqueueSnackbar(message, SNACK_ERROR)
        Api.logger.error(`${message}`, error)
    }
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default connect(mapStateToProps)(withSnackbar(UsersPage));