import { Grid, Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import ProfileDialog from './Dialog/ProfileDialog'
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS, SNACK_ERROR } from './../constants/common';
import { Heart } from 'react-spinners-css';
import { getBootstrapWidthXs } from '../utils';
import DidoHeart from './../components/DidoHeart';


class ProfilesPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { profiles: [], dialogOpen: false, allRoles: [], dialogProfile:{}, dialogProfileRoles:[], width: window.innerWidth, loading: false };
    }

    componentWillMount() {       
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentDidMount() {
        this.updateProfilesList();
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    updateProfilesList = () => {
        this.setState({loading: true})
        Api.getProfiles()
    .then(res =>  this.setState({ profiles: res.data, loading: false}))
    .catch(err =>{
        this.setState({loading: false})
        Api.logger.errorWithSnackbar(`Error get profile list`, this, err)})};

    render() {
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Manage profiles"  onAddFabClick={() => this.showProfileDialog()} />
                <DidoHeart loading={this.state.loading} />
                
                <Container maxWidth="xl" style={{marginTop: '75px'}}>
                    <Grid container spacing={2} direction='row'  alignItems='stretch'>
                        {
                            this.state.profiles.map((profile) => {
                                var xs = getBootstrapWidthXs(this.state.width)
                                return (                                   
                                    <Grid item key={profile.Id} xs={xs} style={{height:'100%'}}>
                                        <Card elevation={1}>
                                            <CardHeader                                               
                                                title={profile.Name}
                                                />
                                            <CardContent><Typography variant="caption" component="span">{profile.Roles.map((role) => role.Name).join(', ')}</Typography></CardContent>
                                            <CardActions disableSpacing>
                                                <IconButton aria-label="Bewerk" onClick={()=>this.showProfileDialog(profile)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="Delete" onClick={()=>this.deleteProfile(profile.Id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </CardActions>
                                            
                                        </Card>
                                    </Grid>
                                                                      
                                );
                            })
                        }
                    </Grid>
                    <ProfileDialog 
                    open={this.state.dialogOpen} 
                    dialogAction={this.dialogAction}
                    allRoles={this.state.allRoles} 
                    dialogProfile={this.state.dialogProfile}
                    dialogProfileRoles={this.state.dialogProfileRoles}                    
                     /> 
                </Container>
    

            </div>);
    }

    dialogAction = (isCanceled, dialogResult) => {
        this.setState({ dialogOpen: false });

        if (isCanceled) {            
            return;
        }

        this.setState({ loading: true });

        let param = {
            name: dialogResult.name,
            roles: this.state.dialogProfileRoles
        }
        
        let promise; 

        if (!this.state.dialogProfile) { // dialog for new profile            
            promise = Api.createProfile(param);
        }
        else {
            param.id = this.state.dialogProfile.Id;
            promise = Api.updateProfile(param);
        }

        promise.then((res) => {
            if (res.data.ResultCode === 0) {
                this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
                this.updateProfilesList();
            }
            else {
                this.logWithSnackbar("Error while edit profile " + res.data.ErrorMessage, res.data)
            }
        })
        .catch(err =>Api.logger.errorWithSnackbar(`Error while edit profile`, this, err))
        .finally(() => this.setState({ loading: false }));
    }

    showProfileDialog = (profile) => {
        Api.getRoles()
            .then(async (response) => {
                
                let roles = response.data.map(p => ({ name: p.Name, id: p.Id })); // todo exclude to Audit fields in DTO
                
                const profileRoles = profile ? profile.Roles.map(p => p.Name) : [];
                await this.setState({
                    allRoles: roles,
                    dialogProfile: profile,
                    dialogProfileRoles: profileRoles,
                    dialogOpen: true
                });
            })
            .catch(err => {
                
                Api.logger.errorWithSnackbar(`Error on get roles list`, this, err)
            });
    }

    deleteProfile(id) {
        Api.deleteProfile(id).then((r) => {
            if (r.data.ResultCode === 0) {
                this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
                this.updateProfilesList()
            }
            else {
                this.logWithSnackbar(`Error on deleting ${id}` + r.data.ErrorMessage, r.data)
            }
        })
            .catch(err => Api.logger.errorWithSnackbar(`Error on deleting ${id}`, this, err));
    }

    logWithSnackbar(message, error){
        this.props.enqueueSnackbar(message, SNACK_ERROR)
        Api.logger.error(`${message}`, error)
    }
}

export default withSnackbar(ProfilesPage);